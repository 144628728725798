<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="button clear button--only-icon menu-item"
      :class="{ 'is-active': isActive || isChildMenuActive }"
      @click="navigate"
    >
      <fluent-icon :icon="icon" />
      <span class="show-for-sr">{{ name }}</span>
      <span v-if="count" class="badge warning">{{ count }}</span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin: var(--space-small) 0;
}

.menu-item {
  display: inline-flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--w-25);

  &:hover {
    border: solid 1px transparent;
    color: white;
    cursor: -webkit-grabbing !important;
    background: #131317 !important;
    box-shadow: 0 32px 60px rgb(0 0 0 / 20%), 0 0 15px rgb(0 0 0 / 10%),
      0 0 0 1px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 50%),
      inset 0 0 0 1px rgb(255 255 255 / 8%), 0 0 0 1px rgb(0 0 0 / 20%) !important;
  }

  &:focus {
    border: solid 1px transparent;
    color: white;
    cursor: -webkit-grabbing !important;
    background: #131317 !important;
    box-shadow: 0 32px 60px rgb(0 0 0 / 20%), 0 0 15px rgb(0 0 0 / 10%),
      0 0 0 1px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 50%),
      inset 0 0 0 1px rgb(255 255 255 / 8%), 0 0 0 1px rgb(0 0 0 / 20%) !important;
  }

  &.is-active {
    border: solid 1px transparent;
    color: white;
    cursor: -webkit-grabbing !important;
    cursor: grabbing !important;
    background: #131317 !important;
    box-shadow: 0 32px 60px rgb(0 0 0 / 20%), 0 0 15px rgb(0 0 0 / 10%),
      0 0 0 1px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 50%),
      inset 0 0 0 1px rgb(255 255 255 / 8%), 0 0 0 1px rgb(0 0 0 / 20%) !important;
  }
}

.icon {
  font-size: var(--font-size-default);
}

.badge {
  position: absolute;
  right: var(--space-minus-smaller);
  top: var(--space-minus-smaller);
}
</style>
